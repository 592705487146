import React, { useEffect, useState } from "react";
import { css, Theme } from "@emotion/react";
import { RouteComponentProps } from "@reach/router";
import { AppViewWrapper } from "./AppViewWrapper";
import Lightbox from "react-image-lightbox";
import debounce from "lodash.debounce";
import { ScrollDiv } from "./Styled/ScrollDiv";
import { ImageRenderer } from "./ImageRenderer";

export const Gallery: React.FC<
  RouteComponentProps & {
    navHeight: number | undefined;
    content: GalleryContent;
  }
> = ({ content, navHeight }) => {
  console.log("cmc", content);
  const [height, setHeight] = useState<number>();
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!window) {
      return;
    }

    if (!height) {
      setHeight(window.innerHeight);
    }

    const resizeListener = () => {
      setHeight(window.innerHeight);
    };

    const debounced = debounce(resizeListener, 100);
    window.addEventListener("resize", debounced);

    return () => {
      debounced.cancel();
      window.removeEventListener("resize", resizeListener);
    };
  }, [height, setHeight]);

  const imageArray = content.gallery.map((item) => ({
    img: `/Images/${item.img}`,
    thumb: `/Images/${item.thumb}`,
  }));

  return navHeight && height ? (
    <AppViewWrapper>
      {open && (
        <Lightbox
          mainSrc={imageArray[photoIndex].img}
          nextSrc={imageArray[(photoIndex + 1) % imageArray.length].img}
          prevSrc={
            imageArray[(photoIndex + imageArray.length - 1) % imageArray.length]
              .img
          }
          mainSrcThumbnail={imageArray[photoIndex].thumb}
          nextSrcThumbnail={
            imageArray[(photoIndex + 1) % imageArray.length].thumb
          }
          prevSrcThumbnail={
            imageArray[(photoIndex + imageArray.length - 1) % imageArray.length]
              .thumb
          }
          onCloseRequest={() => {
            setOpen(false);
            setPhotoIndex(0);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imageArray.length - 1) % imageArray.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageArray.length)
          }
          imageCaption={
            <div css={styles.lightboxCaptionWrapper}>
              {content.gallery[photoIndex]?.title ? (
                <div>{content.gallery[photoIndex].title}</div>
              ) : null}
              <div>{content.gallery[photoIndex].size}</div>
              <div>{content.gallery[photoIndex].material}</div>
              <div>{content.gallery[photoIndex].date}</div>
            </div>
          }
        />
      )}

      <ScrollDiv
        navHeight={navHeight}
        height={height}
        css={[styles.left, styles.section]}
      >
        <div css={styles.title}>{content.title}</div>
        <div css={styles.title}>{content.date}</div>
        {content.showDate ? <div>{content.showDate}</div> : null}
        {content.showDetails ? <div>{content.showDetails}</div> : null}
        <div css={styles.about}>{content.about}</div>
      </ScrollDiv>

      <ScrollDiv
        navHeight={navHeight}
        height={height}
        css={[styles.right, styles.section]}
      >
        {content.gallery.map((item, i) => (
          <div css={styles.galleryWrapper}>
            <ImageRenderer
              img={item.img}
              thumb={item.thumb}
              dimensions={item.dimensions}
              onClick={() => {
                setPhotoIndex(i);
                setOpen(true);
              }}
            />
            <div css={styles.galleryCaptionWrapper}>
              {item.title ? <div>{item.title}</div> : null}
              <div>{item.size}</div>
              <div>{item.material}</div>
              <div>{item.date}</div>
            </div>
          </div>
        ))}
      </ScrollDiv>
    </AppViewWrapper>
  ) : null;
};

const styles = {
  left: (theme: Theme) => css`
    ${theme.media.borderColumnRowLeft}
    font-size:20px;
    padding: 3rem 2rem;
    align-items: flex-start;

    @media ${theme.media.minWidth1000} {
      font-size: 2.08333vw;
      font-size: calc(var(--vw, 1vw) * 2.08333);
      padding: 3rem 8.33vw 2rem 2.5vw;
      padding: 3rem calc(var(--vw, 1vw) * 8.33) 2rem calc(var(--vw, 1vw) * 2.5);
    }
  `,
  right: (theme: Theme) => css`
    ${theme.media.borderColumnRowRight}
    max-height: none;
  `,
  section: css`
    display: flex;
    flex-direction: column;
  `,
  about: (theme: Theme) => css`
    font-size: 16px;

    @media ${theme.media.minWidth1000} {
      font-size: 20px;
    }
  `,
  title: (theme: Theme) => css`
    padding-bottom: 1rem;

    @media ${theme.media.minWidth1000} {
      padding-bottom: 2rem;
    }
  `,
  galleryWrapper: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    @media (max-width: 998px) {
      width: 100%;
    }
    &:not(:last-child) {
      margin-bottom: 4rem;
    }

    @media ${theme.media.minWidth1000} {
      &:not(:last-child) {
        margin-bottom: 8rem;
      }
    }
  `,
  lightboxCaptionWrapper: css`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    text-align: center;
    font-family: "MG Semi-Mono";
    font-size: 16px;
    > div {
      margin-right: 1rem;
    }
  `,
  galleryCaptionWrapper: (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    font-family: "MG Semi-Mono";
    font-size: 12px;

    @media ${theme.media.minWidth1000} {
      min-width: 400px;
      > div {
        width: 50%;
        align-self: flex-end;
      }
    }
  `,
  image: css`
    width: 100%;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;

    :hover {
      transform: scale(1.05);
      transition: transform 0.2s ease-out;
    }
  `,
};

export type GalleryContent = {
  title: string;
  date: string;
  showDate?: string;
  showDetails?: React.ReactNode;
  about: React.ReactNode;
  gallery: {
    img: string;
    thumb: string;
    title?: React.ReactNode;
    size: string;
    material: string;
    date: string;
    dimensions: { width: string; height: string };
  }[];
};
