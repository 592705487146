import React from "react";

export const withCharlotte = {
  title: "With Charlotte",
  date: "2021",
  about: (
    <>
      <p>
        With Charlotte is a small painting series made during summer 2021 for
        the occasion of the West Pride Exhibition. It is about the everyday joys
        of lesbian love and cohabitation from the perspective of an immigrant
        coming from a conservative country – and about fully realising (over and
        over again) that sharing your life with another woman is not only
        possible, but also so joyous. The paintings contain many hidden
        references to the works of other lesbian painters (such as Nicole
        Eisenman for example) in an attempt to situate this work within a
        “lesbian art-historical canon”.
      </p>
    </>
  ),
  gallery: [
    {
      img: "withCharlotte1.jpg",
      thumb: "withCharlotteThumb1.jpg",

      size: "25 × 35 cm",
      material: "Acrylic on canvas.",
      date: "2021",
      dimensions: {
        width: "3054",
        height: "4254",
      },
    },
    {
      img: "withCharlotte2.jpg",
      thumb: "withCharlotteThumb2.jpg",

      size: "25 × 35 cm",
      material: "Acrylic on canvas.",
      date: "2021",
      dimensions: {
        width: "3042",
        height: "4254",
      },
    },
    {
      img: "withCharlotte3.jpg",
      thumb: "withCharlotteThumb3.jpg",

      size: "25 × 35 cm",
      material: "Acrylic on canvas.",
      date: "2021",
      dimensions: {
        width: "2840",
        height: "3885",
      },
    },
    {
      img: "withCharlotte4.jpg",
      thumb: "withCharlotteThumb4.jpg",

      size: "25 × 35 cm",
      material: "Acrylic on canvas.",
      date: "2021",
      dimensions: {
        width: "3036",
        height: "4160",
      },
    },
    {
      img: "withCharlotte5.jpg",
      thumb: "withCharlotteThumb5.jpg",

      size: "25 × 35 cm",
      material: "Acrylic on canvas.",
      date: "2021",
      dimensions: {
        width: "2928",
        height: "4050",
      },
    },
    {
      img: "withCharlotte6.jpg",
      thumb: "withCharlotteThumb6.jpg",

      size: "25 × 35 cm",
      material: "Acrylic on canvas.",
      date: "2021",
      dimensions: {
        width: "4608",
        height: "3456",
      },
    },
  ],
};
