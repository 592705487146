import { css, Theme } from "@emotion/react";

import { Link, RouteComponentProps } from "@reach/router";
import { AppViewWrapper } from "../Components/AppViewWrapper";

export const Home = (_props: RouteComponentProps) => (
  <div css={styles.wrapper}>
    <AppViewWrapper>
      <Link css={[styles.links, styles.left]} to="work">
        <span css={styles.linkText}>work</span>
      </Link>
      <Link css={[styles.links, styles.right]} to="about">
        <span css={styles.linkText}>about</span>
      </Link>
      {/* 
      <div
        css={css`
          padding: 5%;
          width: 100%;
          height: 100%;
        `}
      >
        <div
          css={css`
            border: 4px solid black;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: block;
              padding-left: 15%;
              padding-right: 15%;
              padding-top: 30%;
              padding-bottom: 20%;

              height: auto !important;

              font-size: 9vw;
            `}
          >
            alexandra papademetriou
          </div>
          <div
            css={css`
              width: 100vw;
              border: 2px solid black;
            
            `}
          />

          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-items: center;
              padding: 31% 15%;
            `}
          >
            <Link
              to="work"
              css={css`
                background: #ff5500;
                border: 4px solid black;
                text-align: center;
                padding: 10% 0;
                margin-bottom: 20%;
              `}
            >
              <span css={styles.linkText}>work</span>
            </Link>
            <Link
              to="about"
              css={css`
                background: #fbcb0f;
                border: 4px solid black;
                text-align: center;
                padding: 10% 0;
              `}
            >
              <span css={styles.linkText}>about</span>
            </Link>
          </div>
        </div>
      </div> */}

      {/* <Link to="work">
        <span css={styles.linkText}>work</span>
      </Link>
      <Link to="about">
        <span css={styles.linkText}>about</span>
      </Link> */}
    </AppViewWrapper>
  </div>
);

const styles = {
  desktop: (theme: Theme) => css`
    @media ${theme.media.maxWidth999} {
      display: none;
    }
  `,
  wrapper: (theme: Theme) => css`
    ${theme.styles.wrapper}
  `,
  links: (theme: Theme) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ${theme.transition.background};
  `,
  left: (theme: Theme) => css`
    ${theme.media.borderColumnRowLeft}

    :hover {
      background: ${theme.colors.orange};
    }
  `,
  right: (theme: Theme) => css`
    ${theme.media.borderColumnRowRight}

    :hover {
      background: ${theme.colors.yellow};
    }
  `,
  linkText: (theme: Theme) => css`
    font-size: 75px;
    @media ${theme.media.maxWidth599} {
      font-size: 10vw;
      font-size: calc(var(--vw, 1vw) * 10);
    }
    @media ${theme.media.minWidth1000} {
      font-size: 5.2083vw;
      font-size: calc(var(--vw, 1vw) * 5.2083);
    }
  `,
};
