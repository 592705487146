import React from "react";

export const seminal = {
  title: "This Work is Not Seminal",
  date: "2015",
  about: (
    <>
      <p>
        <i>This Work Is Not Seminal</i> is a series of paintings exploring my
        complex relationship with systems of language and visual communication,
        as they have been shaped by millenia of androcentric culture. Being both
        female and homosexual, “a woman-identified woman”, these paintings are
        an attempt to create a space for myself in this culture which has
        branded me as <em>Other</em>. The works emerge from a rejection of
        traditional painterly forms and a desire to create a new visual language
        – experimenting with unusual materials and a selective colour palette.
      </p>

      <p>
        Drawing from art history, literature, and popular culture, the paintings
        of this series challenge the phallocentrism embedded in our language and
        in popular narratives; such as the myth of the (invariably male) genius
        artist.
      </p>

      <p>
        At the same time, the works explore aspects of female experience and
        girlhood which are systematically pushed to the margins of public
        discourse, experiences which are often entirely taboo.{" "}
        <em>How can we begin to talk about that which is kept nameless?</em>
      </p>

      <p>
        In the first installation of <i>This Work Is Not Seminal</i> in 2015,
        the 148 works of the series were hung so as to entirely cover the walls
        of a room with the intent of creating a safe space markedly different
        from its surrounding environment. A DIY table and chairs, together with
        a hand-painted deck of cards, were placed in the centre of the space –
        inviting visitors to activate the installation by using the space to
        talk freely, meet each other, play, or even simply rest within this safe
        space.
      </p>
    </>
  ),
  gallery: [
    {
      img: "seminal1.jpg",
      thumb: "seminalThumb1.jpg",
      size: "50 × 59 cm",
      material: "Acrylic paint, latex paint, packing paper.",
      date: "2015",
      dimensions: {
        width: "2124",
        height: "2616",
      },
    },
    {
      img: "seminal2.jpg",
      thumb: "seminalThumb2.jpg",
      size: "110 × 101 cm",
      material: "Acrylic paint, latex paint, newspaper.",
      date: "2015",
      dimensions: {
        width: "2934",
        height: "2628",
      },
    },
    {
      img: "seminal3.jpg",
      thumb: "seminalThumb3.jpg",
      size: "60 × 80 cm",
      material: "Acrylic paint, latex paint, vinyl glue, twine, newspaper.",
      date: "2015",
      dimensions: {
        width: "2043",
        height: "2697",
      },
    },
    {
      img: "seminal4.jpg",
      thumb: "seminalThumb4.jpg",
      size: "37 × 58 cm",
      material: "Acrylic paint, found paper.",
      date: "2015",
      dimensions: {
        width: "1708",
        height: "2736",
      },
    },
    {
      img: "seminal5.jpg",
      thumb: "seminalThumb5.jpg",
      size: "54 × 55 cm",
      material: "Acrylic paint, latex paint, vinyl glue, newspaper.",
      date: "2015",
      dimensions: {
        width: "2340",
        height: "2547",
      },
    },
    {
      img: "seminal6.jpg",
      thumb: "seminalThumb6.jpg",
      size: "21 × 29.7 cm",
      material: "Acrylic paint, latex paint, copy paper.",
      date: "2015",
      dimensions: {
        width: "2444",
        height: "3456",
      },
    },
    {
      img: "seminal7.jpg",
      thumb: "seminalThumb7.jpg",
      size: "70 × 100 cm",
      material: "Acrylic paint, latex paint, charcoal, cardboard.",
      date: "2015",
      dimensions: {
        width: "1878",
        height: "2658",
      },
    },
    {
      img: "seminal8.jpg",
      thumb: "seminalThumb8.jpg",
      size: "44 × 43 cm",
      material: "Acrylic paint, latex paint, packing paper.",
      date: "2015",
      dimensions: {
        width: "2490",
        height: "2496",
      },
    },
  ],
};
