import React from "react";

export const anotherTime = {
  title: "…even in another time",
  date: "2022",
  about: (
    <>
      <p>
        <i>…even in another time</i> is a series of 22 paintings designed
        originally for the space of Page 28, an LGBTQ+ bookstore in Malmö. The
        paintings explore symbols, figures, and motifs from LGBTQ literature and
        art through the ages, gesturing towards a Queer cultural historiography.
      </p>
      <p>
        While LGBTQ culture and history have been systematically obscured or
        even outright destroyed, fragments of our collective past – manuscripts,
        paintings, photographs – have persisted and continue to act as landmarks
        for our community.
      </p>
      <p>
        And, although our current culture is increasingly moving towards
        ephemeral and online artistic production, the works here argue that
        labouring to create material spaces and objects is vital for our
        continued sense of community and cultural identity.
      </p>
    </>
  ),
  gallery: [
    {
      img: "anotherTime1.jpg",
      thumb: "anotherTimeThumb1.jpg",
      title: <i>Untitled (after Nicole Eisenman)</i>,
      size: "50 × 40 cm",
      material: "Acrylic paint, glue, newspaper.",
      date: "2022",
      dimensions: {
        width: "3730",
        height: "3009",
      },
    },
    {
      img: "anotherTime2.jpg",
      thumb: "anotherTimeThumb2.jpg",
      title: <i>Untitled (after Thurston Hopkins)</i>,
      size: "21 × 32 cm",
      material: "Acrylic paint, glue, newspaper.",
      date: "2022",
      dimensions: {
        width: "2859",
        height: "3151",
      },
    },
    {
      img: "anotherTime3.jpg",
      thumb: "anotherTimeThumb3.jpg",
      title: <i>Untitled (after Johann Friedrich Overbeck)</i>,
      size: "47 × 35 cm",
      material: "Acrylic paint, glue, newspaper.",
      date: "2022",
      dimensions: {
        width: "4773",
        height: "3446",
      },
    },
    {
      img: "anotherTime4.jpg",
      thumb: "anotherTimeThumb4.jpg",
      title: <i>Untitled (after Guido Reni)</i>,
      size: "56 × 40 cm",
      material: "Acrylic paint, glue, newspaper.",
      date: "2022",
      dimensions: {
        width: "4992",
        height: "3560",
      },
    },
    {
      img: "anotherTime5.jpg",
      thumb: "anotherTimeThumb5.jpg",
      title: "Installation at Page 28.",
      size: "",
      material: "",
      date: "2022",
      dimensions: {
        width: "4608",
        height: "3456",
      },
    },
    {
      img: "anotherTime6.jpg",
      thumb: "anotherTimeThumb6.jpg",
      title: "Installation at Page 28.",
      size: "",
      material: "",
      date: "2022",
      dimensions: {
        width: "4608",
        height: "3456",
      },
    },
    {
      img: "anotherTime7.jpg",
      thumb: "anotherTimeThumb7.jpg",
      title: "Installation at Page 28.",
      size: "",
      material: "",
      date: "2022",
      dimensions: {
        width: "3456",
        height: "4608",
      },
    },
  ],
};
