import React from "react";

export const pulp = {
  title: "Pulp",
  date: "2014",
  about: (
    <>
      <p>
        The women depicted in the 20 drawings of <bold>Pulp</bold> are taken
        from lesbian-themed pulp fiction covers. In isolating the faces,
        attention is drawn to the way lesbian women are depicted in our culture;
        while the process of observing and replicating these depictions refers
        to the way societal stereotypes and perceptions are learned and
        perpetuated. With storytelling being a fundamentally human way of
        mapping and understanding the world, the lack of proper lesbian
        representation across all forms of media has left countless women
        feeling alienated from society and feeling an absence of cultural
        identity.
      </p>
    </>
  ),
  gallery: [
    {
      img: "pulp1.jpg",
      thumb: "pulpThumb1.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "2200",
        height: "3132",
      },
    },
    {
      img: "pulp2.jpg",
      thumb: "pulpThumb2.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1664",
        height: "2368",
      },
    },
    {
      img: "pulp3.jpg",
      thumb: "pulpThumb3.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "2168",
        height: "3000",
      },
    },
    {
      img: "pulp4.jpg",
      thumb: "pulpThumb4.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1640",
        height: "2328",
      },
    },
    {
      img: "pulp5.jpg",
      thumb: "pulpThumb5.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1676",
        height: "2372",
      },
    },
    {
      img: "pulp6.jpg",
      thumb: "pulpThumb6.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1652",
        height: "2372",
      },
    },
    {
      img: "pulp7.jpg",
      thumb: "pulpThumb7.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1648",
        height: "2324",
      },
    },
    {
      img: "pulp8.jpg",
      thumb: "pulpThumb8.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1652",
        height: "2356",
      },
    },
    {
      img: "pulp9.jpg",
      thumb: "pulpThumb9.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1680",
        height: "2364",
      },
    },
    {
      img: "pulp10.jpg",
      thumb: "pulpThumb10.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "2292",
        height: "3172",
      },
    },
    {
      img: "pulp11.jpg",
      thumb: "pulpThumb11.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1632",
        height: "2316",
      },
    },
    {
      img: "pulp12.jpg",
      thumb: "pulpThumb12.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1876",
        height: "2692",
      },
    },
    {
      img: "pulp13.jpg",
      thumb: "pulpThumb13.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1924",
        height: "2704",
      },
    },
    {
      img: "pulp14.jpg",
      thumb: "pulpThumb14.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1680",
        height: "2360",
      },
    },
    {
      img: "pulp15.jpg",
      thumb: "pulpThumb15.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1904",
        height: "2692",
      },
    },
    {
      img: "pulp16.jpg",
      thumb: "pulpThumb16.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1656",
        height: "2368",
      },
    },
    {
      img: "pulp17.jpg",
      thumb: "pulpThumb17.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1680",
        height: "2376",
      },
    },
    {
      img: "pulp18.jpg",
      thumb: "pulpThumb18.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1548",
        height: "2192",
      },
    },
    {
      img: "pulp19.jpg",
      thumb: "pulpThumb19.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1900",
        height: "2696",
      },
    },
    {
      img: "pulp20.jpg",
      thumb: "pulpThumb20.jpg",
      size: "29.7 × 42 cm",
      material: "Graphite on tracing paper.",
      date: "2014",
      dimensions: {
        width: "1676",
        height: "2360",
      },
    },
  ],
};
