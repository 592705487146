import React from "react";

export const bodiesInAlliance = {
  title: "Bodies in Alliance / Bodies in Defiance",
  date: "2019",
  showDate: "June 13-16, Monitor and Rotor2 Galleries",
  showDetails: (
    <>
      Curated together with Gloria López-Cleries, Sanna Samuelsson, Kolbrún Inga
      Söring.
      <br />
      Realised with the support of KonstKåren and A-Venue.
    </>
  ),
  about: (
    <>
      <p>
        This exhibition aimed to be an open non-commercial platform during the
        week of West Pride where artists, performers, academics, and audiences
        could collectively reflect on queer subjectivity. This took the form of
        an exhibition spanning Valand Academy’s two student galleries including
        bachelor and master students from the departments of fine art, literary
        composition, ceramics, jewellery, and photography – as well as a 4-day
        public programme including performances, readings, interactive
        screenings, group meals, zine workshops, and public talks.
      </p>

      <p>
        The title is borrowed from Judith Butler’s{" "}
        <i>Bodies in Alliance and the Politics of the Street</i>, a text which
        was proposed as a common point of reference. To protest is to be
        present, to be visible; and as such, to be placed in a vulnerable
        position. It is, however, only through this collective vulnerability,
        this acknowledgement of our bodies as fragile and co-dependant, that we
        can claim space for ourselves – and finally have our many voices heard.
      </p>
      <p>
        Participating artists:
        <br />
        Alexander Stevenson
        <br />
        Alexandra Papademetriou
        <br />
        Benjamin Rask
        <br />
        Gloria López-Cleries
        <br />
        Hanna Stenman
        <br />
        Higo Mental
        <br />
        Jo Öqvist
        <br />
        Jussi Järvinen
        <br />
        Kolbrún Inga Söring
        <br />
        Kristoffer Appelvik Lax
        <br />
        Laurence Price
        <br />
        Lex Eliot Rose
        <br />
        Lucy Wilson
        <br />
        Mustafa Boğa
        <br />
        Reyhaneh Mirjahani
        <br />
        Ruth Gilmour
        <br />
        Sanna Samuelsson
      </p>
    </>
  ),
  gallery: [
    {
      img: "bodiesInAlliance1.jpg",
      thumb: "bodiesInAllianceThumb1.jpg",

      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "4032",
        height: "3024",
      },
    },
    {
      img: "bodiesInAlliance2.jpg",
      thumb: "bodiesInAllianceThumb2.jpg",
      title: "Photo by Javid Sina.",
      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "4928",
        height: "3264",
      },
    },
    {
      img: "bodiesInAlliance3.jpg",
      thumb: "bodiesInAllianceThumb3.jpg",
      title: "Photo by Javid Sina.",
      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "4928",
        height: "3264",
      },
    },
    {
      img: "bodiesInAlliance4.jpg",
      thumb: "bodiesInAllianceThumb4.jpg",
      title: "Photo by Reyhaneh Mirjahani.",
      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "4928",
        height: "3264",
      },
    },
    {
      img: "bodiesInAlliance5.jpg",
      thumb: "bodiesInAllianceThumb5.jpg",

      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "4032",
        height: "3024",
      },
    },
    {
      img: "bodiesInAlliance6.jpg",
      thumb: "bodiesInAllianceThumb6.jpg",
      title: "Photo by Javid Sina.",
      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "4928",
        height: "3264",
      },
    },
    {
      img: "bodiesInAlliance7.jpg",
      thumb: "bodiesInAllianceThumb7.jpg",

      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "3054",
        height: "1921",
      },
    },

    {
      img: "bodiesInAlliance8.jpg",
      thumb: "bodiesInAllianceThumb8.jpg",

      size: "",
      material: "",
      date: "",
      dimensions: {
        width: "2448",
        height: "3264",
      },
    },
  ],
};
