import React from "react";

export const rememberFun = {
  title: "Remember when this used to be fun? ",
  date: "2022",
  showDate: "June 17-19, Vulkano, Södra Änggården",
  showDetails: (
    <>
      Curated with assistance from{" "}
      <a
        href="https://www.reyhanehmirjahani.com/"
        target="_blank"
        rel="noreferrer"
      >
        Reyhaneh Mirjahani
      </a>
      .
      <br />
      Realised with the support of KulturUngdom.
    </>
  ),
  about: (
    <>
      <p>From the exhibition booklet:</p>
      <p>
        <i>
          When was the last time you saw an artwork that was fun to look at?{" "}
          <br />
          When was the last time you made something that was fun to make? <br />
          When was the last time you made something that didn’t have to be
          approved by a committee of strangers before it could be made? <br />
          Can an artwork that is pushed through and moulded by a bureaucratic
          process still be fun? <br />
          Can it still represent personal, lived human experience? <br />
          How much can it relate to its viewers, to you and me? <br />
          If an artwork has to be approved by funding committees and academic
          juries before it is made, is artistic expression still free? <br />
          If training in application writing is necessary to make art, is
          artistic expression still free? <br />
          Is this art made for bureaucrats? <br />
          Are the bureaucrats having fun? <br />
          Within a system that puts intellectualisation, productivity, and
          efficiency above all else, can fun be subversive?
        </i>
      </p>

      <p>
        The aim of this group exhibition was to question and nuance the current
        shift towards highly academicised, highly bureaucratised art – while at
        the same time making space for a more spontaneous, more autonomous, more
        playful mode of art-making. Nine Gothenburg artists, all of whom work
        with/in artistic research, gathered to create and to present projects
        they love but which do not otherwise have space to exist within their
        institutional practices.
      </p>
      <p>
        Participating artists:
        <br />
        Kasra Seyed Alikhani
        <br />
        James Duffy
        <br />
        Reyhaneh Mirjahani
        <br />
        Johanna Oskarsson
        <br />
        Alexandra Papademetriou
        <br />
        Beate Persdotter Løken
        <br />
        Una Sigtryggsdóttir
        <br />
        Åke Sjöberg
        <br />
        Alexander Stevenson
      </p>
    </>
  ),
  gallery: [
    {
      img: "rememberFun1.jpg",
      thumb: "rememberFunThumb1.jpg",
      title: "Photo by Philip Liljenberg.",
      size: "Poster design by Alexandra Papademetriou.",
      material: "",
      date: "",
      dimensions: {
        width: "3000",
        height: "2000",
      },
    },
    {
      img: "rememberFun2.jpg",
      thumb: "rememberFunThumb2.jpg",
      title: "Photo by Philip Liljenberg.",
      size: (
        <>
          Pictured: <br />
          Artwork by Alexandra Papademetriou (background).
          <br />
          Artwork by James Duffy (foreground).
        </>
      ),
      material: "",
      date: "",
      dimensions: {
        width: "3000",
        height: "2000",
      },
    },
    {
      img: "rememberFun3.jpg",
      thumb: "rememberFunThumb3.jpg",
      title: "Photo by Philip Liljenberg.",
      size: (
        <>
          Pictured: Artwork by{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.alexanderstevenson.com/"
          >
            Alexander Stevenson
          </a>
          .
        </>
      ),
      material: "",
      date: "",
      dimensions: {
        width: "3000",
        height: "2000",
      },
    },
    {
      img: "rememberFun4.jpg",
      thumb: "rememberFunThumb4.jpg",
      title: "Photo by Philip Liljenberg.",
      size: (
        <>
          Pictured: Artwork by{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.johannaoskarsson.com/"
          >
            Johanna Oskarsson
          </a>
          .
        </>
      ),
      material: "",
      date: "",
      dimensions: {
        width: "2394",
        height: "1937",
      },
    },
    {
      img: "rememberFun5.jpg",
      thumb: "rememberFunThumb5.jpg",
      title: "Photo by Philip Liljenberg.",
      size: (
        <>
          Pictured: Artwork by{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://asjoberg.wordpress.com/"
          >
            Åke Sjöberg
          </a>
          .
        </>
      ),
      material: "",
      date: "",
      dimensions: {
        width: "3000",
        height: "2000",
      },
    },
    {
      img: "rememberFun6.jpg",
      thumb: "rememberFunThumb6.jpeg",
      title: "Photo by Philip Liljenberg.",
      size: (
        <>
          Pictured: Artwork by{" "}
          <a target="_blank" rel="noreferrer" href="https://www.unasig.com/">
            Una Sigtryggsdòttir
          </a>
          .
        </>
      ),
      material: "",
      date: "",
      dimensions: {
        width: "3000",
        height: "2000",
      },
    },
  ],
};
