import React from "react";

export const meadow = {
  title: "This box contains a meadow",
  date: "2023",
  about: (
    <>
      <p>
        This project was designed in response to the industrial (and
        soon-to-be-redeveloped) area of Lövholmen in Stockholm, and the locals
        living in the surrounding neighbourhood of Gröndal. The aim was to
        invite locals and passers-by in a conversation about the right to public
        space and citizen engagement with the impending redevelopment process.
      </p>
      <p>
        During research, it was discovered that the real estate companies owning
        the Lövholmen area were, in fact, making the conscious choice to keep
        the area as dead as possible during the many years of negotiation with
        the municipality, creating a landscape of wire fences, gravel pits, and
        rusting industrial machinery. This is part of a conscious strategy to
        reduce citizen resistance to their own proposals for the area.
      </p>
      <p>
        This seed bomb box was installed in a strategic place, near Lövholmen
        and at the intersection of two major roads running through the
        neighbourhood. Each seed bomb contained a mix of native flowers, many of
        which are known for their ability to flourish even on gravel ground
        and/or for their phytoremediatory properties. The invitation was to take
        as many seed bombs as one wished, and plant them anywhere in the
        neighbourhood. The act of seed bombing is, of course, illegal – unlike
        the act of creating a wasteland.
      </p>
      <p>Realised in collaboration with Jenna Jauhiainen.</p>
    </>
  ),
  gallery: [
    {
      img: "meadow1.jpg",
      thumb: "meadowThumb1.jpg",
      dimensions: {
        width: "1334",
        height: "2000",
      },
    },
    {
      img: "meadow2.jpg",
      thumb: "meadowThumb2.jpg",
      dimensions: {
        width: "1500",
        height: "2000",
      },
    },
    {
      img: "meadow3.jpg",
      thumb: "meadowThumb3.jpg",
      dimensions: {
        width: "1500",
        height: "2000",
      },
    },
    {
      img: "meadow4.jpg",
      thumb: "meadowThumb4.jpg",
      dimensions: {
        width: "2000",
        height: "1994",
      },
    },
    {
      img: "meadow5.jpg",
      thumb: "meadowThumb5.jpg",
      dimensions: {
        width: "1333",
        height: "2000",
      },
    },
  ],
};
