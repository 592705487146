import React from "react";

export const phyto = {
  title: "The Active Citizen’s (Short) Guide to Phytoremediation",
  date: "2023",
  about: (
    <>
      <p>
        <i>The Active Citizen’s (Short) Guide to Phytoremediation</i> is part of
        an artistic research project exploring how phytoremediation methods can
        be used by both individuals and communities as environmental action.
      </p>
      <p>
        <i>Phytoremediation</i> is a method of cleaning soil pollution that uses
        plants and their natural processes to break down or remove pollutants
        from the earth.
      </p>
      <p>
        Read the guide online here:
        <br />
        <a
          href="https://alexandrapapademetriou.com/phytoremediationENG.pdf"
          target="_blank"
        >
          English
        </a>{" "}
        <br />
        <a
          href="https://alexandrapapademetriou.com/phytoremediationSWE.pdf"
          target="_blank"
        >
          Swedish
        </a>
      </p>
    </>
  ),
  gallery: [
    {
      img: "phyto1.jpg",
      thumb: "phytoThumb1.jpg",
      dimensions: {
        width: "2000",
        height: "1978",
      },
    },
    {
      img: "phyto2.jpg",
      thumb: "phytoThumb2.jpg",
      dimensions: {
        width: "2000",
        height: "2000",
      },
    },
    {
      img: "phyto3.jpg",
      thumb: "phytoThumb3.jpg",
      dimensions: {
        width: "2000",
        height: "2000",
      },
    },
    {
      img: "phyto4.jpg",
      thumb: "phytoThumb4.jpg",
      dimensions: {
        width: "2000",
        height: "2000",
      },
    },
    {
      img: "phyto5.jpg",
      thumb: "phytoThumb5.jpg",
      dimensions: {
        width: "2000",
        height: "2000",
      },
    },
    {
      img: "phyto6.jpg",
      thumb: "phytoThumb6.jpg",
      date: "2023",
      dimensions: {
        width: "2000",
        height: "2000",
      },
    },
  ],
};
